.academyCoursesOverviewArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 200px;
  margin-bottom: 100px;
}

.academyCoursesImageList {
  width: 100%;
  height: 650px;
  max-width: 2000px;
}

.academyCoursesOverviewBlock {
  height: 650px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.academyCoursesOverviewImage {
  object-fit: contain;
  width: 100%;
  transition: scale 250ms;
}

.academyCoursesOverviewBlock:hover .academyCoursesOverviewImage {
  scale: 1.1;
  opacity: 0.7;
}

.academyCoursesOverviewDescription {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  /* bottom: 50px; */
  /* padding: 0 30px; */
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
}

.academyCoursesOverviewDescription > p {
  padding: 0 2vw;
}

.academyCoursesOverviewDescription > *:last-child {
  margin-bottom: 50px;
}

.academyCoursesOverviewBlock:hover .academyCoursesOverviewDescription {
  opacity: 1;
  z-index: 1;
}

@media screen and (max-width: 750px) {
  .academyCoursesImageList {
    height: auto;
  }

  .academyCoursesOverviewBlock {
    height: auto;
    aspect-ratio: 9/16;
  }
}

@media screen and (max-width: 500px) {
  .academyCoursesOverviewBlock {
    aspect-ratio: 1/1;
  }
}
