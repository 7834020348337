.agarthaAcademyButton {
  display: flex;
  height: 65px;
  width: 300px;
  max-width: 80vw;
  border-radius: 8px !important;
  border: 2px solid #26272b !important;
  color: white !important;
  background: #1b1b1b !important;
  border-radius: 50px;
  transition: scale 250ms !important;
}

.agarthaAcademyButton:hover {
  color: #26272b !important;
  background: white !important;
  font-size: 15px;
  scale: 0.95;
}

a {
  text-decoration: none;
  width: auto;
  max-width: 80vw;
}
