.academyHeaderLinks {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1vw;
  overflow-y: auto;
  margin-left: 50px;
  margin-right: 30px;
}

.agarthaAcademyLogoContainer {
  display: flex;
  margin-left: 30px;
}

@media screen and (max-width: 1024px) {
  .academyHeaderLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: auto;
    border-top: 1px solid #26272b;
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
  }
}
