.labsCes2023Area {
  position: relative;
  min-height: 100vh;
}

.labsCes2023Header {
  width: 100vw;
  padding-top: 25px;
  padding-bottom: 10px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.labsCes2023MainContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 530px;
  padding: 100px 7vw 100px 7vw;
  gap: 100px;
}

.labsCes2023DescriptionBlock {
  width: 100%;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  gap: 100px;
}

.labsCes2023OverviewButtons {
  width: 100%;
  height: 60px;
  display: flex;
  gap: 30px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
}

.labsCes2023Description {
  min-height: 250px;
  height: auto;
  border-radius: 20px;
  background: linear-gradient(90deg, #0d1925 0%, #002434 100%);
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.labsCes2023DescriptionProjects {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 500px;
  border-radius: 20px;
  background: linear-gradient(90deg, #0d1925 0%, #002434 100%);
  padding: 25px 50px;
}

.labsCes2023DescriptionProjectText {
  /* text-align: left;
    text-align-last: left; */
  font-size: 12pt;
}

.labsCes2023OverviewImage {
  position: relative;
  object-fit: contain;
  height: 300px;
  width: auto;
  left: 50%;
  transform: translate(-50%);
  border-radius: 20px;
}

.labsCes2023DescriptionLinks {
  display: flex;
  align-items: center;
  gap: 20px;
}

.labsCes2023DescriptionAgarthaSummary {
  display: flex;
  justify-content: center;
  width: 100vw;
  gap: 50px;
  background: linear-gradient(90deg, #0d1925 0%, #002434 50%, #003839 100%);
}
.labsCes2023AgarthaLabsTitle {
  font-size: 20pt;
}

.labsCes2023DescriptionTitle {
  font-size: 22pt;
  margin: 0;
}

.labsCes2023DescriptionText {
  font-size: 12pt;
}

.labsCes2023OverviewInfoButtons > p {
  font-size: 12pt;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labsCes2023DescriptionContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 20px;
}

.ces2023Button {
  height: 60px;
  max-width: 400px;
  min-width: 150px !important;
  width: 20vw !important;
  max-width: 100%;
  border-radius: 20px !important;
  border: 2px solid #131313 !important;
  background-color: #fdfdfb !important;
  margin-top: 20px;
  margin-bottom: 10px;
  text-decoration: none;
  padding: 0 !important;
  transition: scale 250ms !important;
}

.ces2023Button:hover {
  background: #1b1b1b !important;
  border: 2px solid rgb(87, 87, 87) !important;
  color: white !important;
  scale: 0.95;
}

.ces2023LinkApparence {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  height: 100%;
  width: 100%;
}

.ces2023LinkApparence:hover {
  color: #fdfdfb !important;
}

.labsCes2023OverviewInfoButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 20vw;
  min-width: 220px;
  height: 60px;
  border-radius: 20px;
  background-color: #1b1b1b;
}

.labsCes2023SeparationBar {
  border-left: 1px solid #555555;
}

.labsCes2023ReturnButton::before {
  content: "";
  position: absolute;
  display: flex;
  top: 80%;
  width: 90%;
  left: 5%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(45deg, #cfe4ae 0%, #139a73 50%, #191364 100%);
}

.labsCes2023HeaderButtons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.labsCesLogo {
  width: 230px;
  object-fit: contain;
}

.svgContainerLabsCes2023Left {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin: 0;
}

.svgContainerLabsCes2023Right {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin: 0;
}

.labsCes2023LinkApparence {
  text-decoration: none;
  font-size: 18pt !important;
  font-weight: bold;
}

.labsCes2023LinksBlock {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: 50px;
  gap: 100px;
}

.blackBackground {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  gap: 100px;
}

.labsCes2023OverviewPicture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.labsCes2023Summary {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  border-radius: 20px;
  background: linear-gradient(90deg, #002434 0%, #0d1925 100%);
  padding: 25px 50px;
}

.labsCesSummaryLogo {
  width: 70%;
  margin: 10px auto;
}

.labsCes2023Description > p {
  text-align: left;
  text-align-last: left;
}

.ces2023Button > h4 {
  color: black !important;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ces2023Button > a {
  color: black !important;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.ces2023Button > h4:hover {
  color: white !important;
}

@media screen and (max-width: 1500px) {
  .labsCes2023DescriptionBlock {
    flex-direction: column;
    margin: 0;
  }

  .labsCes2023OverviewImage {
    width: 100%;
    height: auto;
  }

  .ces2023Button {
    width: 50% !important;
    margin: 10px;
    min-width: 250px !important;
  }

  .labsCes2023MainContainer {
    padding-top: 50px;
  }

  .labsCes2023LinksBlock {
    margin: 0;
    width: 100%;
  }

  .labsCes2023Summary {
    width: auto;
    align-items: center;
  }

  .labsCes2023LinksBlock {
    flex-direction: column;
  }

  .labsCes2023DescriptionProjects {
    width: auto;
  }

  .labsCesSummaryLogo {
    width: 50%;
    min-width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .labsCes2023AgarthaLabsTitle {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .labsCes2023Description {
    background: linear-gradient(90deg, #002434 0%, #0d1925 100%);
  }
}

@media screen and (max-width: 550px) {
  .labsCes2023Description {
    padding: 30px 20px;
    align-items: center;
  }

  .labsCes2023Description > p {
    text-align: center;
    text-align-last: center;
  }

  .ces2023Button {
    width: 100% !important;
    min-width: none;
  }

  .labsCes2023MainContainer {
    gap: 70px;
  }

  .labsCes2023DescriptionBlock {
    gap: 70px;
  }

  .labsCes2023MainContainer {
    padding: 50px 20px;
  }

  .labsCes2023LinksBlock {
    gap: 70px;
    margin-bottom: 50px;
  }

  .labsCes2023DescriptionTitle {
    text-align: center;
  }

  .labsCes2023Summary {
    padding: 30px 20px;
  }

  .labsCes2023DescriptionProjects {
    padding: 30px 20px;
  }
}
