.labsSvgContainer2 {
  position: absolute;
  overflow-y: visible;
  overflow-x: hidden;
  top: -45px;
  right: 0;
  height: 1025px;
  width: 100%;
}

.labsSvgRune2 {
  position: absolute;
  right: 0;
  height: 915px;
}
