.agarthaLogo {
  margin-left: 50px;
  width: 230px;
}

.agarthaLogo:hover {
  cursor: pointer;
  scale: 0.99;
}

@media screen and (max-width: 1150px) {
  .agarthaLogo {
    margin-left: 30px;
  }
}

@media screen and (max-width: 450px) {
  .agarthaLogo {
    margin-left: 5px;
  }
}
