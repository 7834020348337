.academySvg1 {
  position: absolute;
  right: 100px;
  width: 640px;
}

@media screen and (max-width: 1700px) {
  .academySvg1 {
    right: auto;
    left: calc(45vw + 200px);
  }
}

@media screen and (max-width: 1100px) {
  .academySvg1 {
    right: -150px;
    left: auto;
  }
}
