.solanaSvgIcon {
  height: 25px;
  object-fit: contain;
  transition: scale 250ms;
}

.solanaSvgIcon:hover {
  cursor: pointer !important;
  scale: 1.15;
}
