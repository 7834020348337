.agarthaLabsLightLogo {
  width: auto;
  height: 50px;
}

@media screen and (max-width: 500px) {
  .agarthaLabsLightLogo {
    height: 25pt !important;
  }
}
