@font-face {
  font-family: "monarchFont";
  src: local("monarchFont"),
    url("../../../../fonts/monarch-display/MonarchDisplay-Tantum.otf");
}

.agarthaButtonRoadmap {
  display: flex;
  color: white;
  width: 100%;
  height: 100%;
}

.roadmapDetailedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border: 1px solid #26272b;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10vh 20px;
}

.agarthaButtonRoadmap > h2 {
  color: white;
}

.topContainerDetails {
  position: relative;
  display: flex;
  width: 89%;
  margin-right: auto;
  align-items: flex-start;
}

.topContainerDetails > p {
  text-align: center;
}

.botContainerDetails {
  position: relative;
  display: flex;
  justify-content: center;
  width: 89%;
  margin-left: auto;
  align-items: flex-start;
}

.agarthaRoadmapModalDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  left: 10px;
  color: white;
  margin: 0;
  font-size: 11pt;
  flex: 1;
}

.monarchFont {
  font-family: "monarchFont";
}

.littleHorizontalSize {
  height: 80px;
}

.roadmapTitleFocus {
  font-family: "AvenirBlack";
  font-size: 14pt;
  border-bottom: 1px solid #26272b;
  padding-bottom: 2px;
  margin-bottom: 5px;
}
