.languageSelector {
  color: white !important;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-left: 4px;
}

.languageSelectorContainer {
  width: 100%;
}

.emptyValueSelector {
  color: white !important;
  border-bottom: none !important;
  text-align: center;
}

.emptyValueSelector > svg {
  color: white;
}

.emptyValueSelector::after {
  border-bottom: 2px solid white !important;
}

.menuItemSelection {
  color: white !important;
}

.MuiPaper-elevation1 {
  background-color: black !important;
}

.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
  border-bottom: none !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: rgb(161 169 177 / 8%) !important;
}

@media screen and (max-width: 1200px) {
  .languageSelector {
    max-width: 80px;
  }

  .css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .languageSelector {
    max-width: inherit;
  }
}
