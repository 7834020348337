.researchBlocks {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.labsResearchArea {
  position: relative;
  min-height: 550px;
  margin-bottom: 100px;
  margin-top: 100px;
  padding-top: 250px;
}

.researchBlock {
  display: flex;
  margin-top: 7vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  padding: 20px 10px 20px 20px;
}

.researchDescription > p {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 22px;
  font-size: 12pt;
}

.researchDescription > h3 {
  font-size: 22pt;
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
}

.svgContainer9 {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  overflow-y: visible;
  overflow-x: hidden;
  top: 0;
  width: 100%;
}

@media screen and (max-width: 750px) {
  .labsResearchArea {
    margin-bottom: 100px;
  }

  .researchBlocks {
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }

  .researchBlock {
    flex-direction: row;
    width: 80vw;
    gap: 7vw;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 50px;
    padding-left: 30px;
  }

  .researchDescription > p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .researchDescription > h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
