.textAboutArea {
  min-height: 860px;
  position: relative;
  padding-top: 200px;
}

.storytellingArea {
  position: absolute;
  display: flex;
  align-items: center;
  width: auto;
  width: 100vw;
  height: 600px;
  left: 0;
  right: 0;
  bottom: 220px;
  overflow: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.storytellingArea::-webkit-scrollbar {
  display: none;
}

.textAbout {
  display: flex;
  flex-direction: column;
  padding: 20px 10vw;
  position: absolute;
  color: white;
  height: auto;
  top: auto;
  left: calc(450px + 5vw);
  right: 2vw;
}

.textAbout > p {
  text-align: center;
  text-align-last: center;
  font-size: 12pt;
}

.svgContainer2 {
  position: absolute;
  width: 100%;
  height: 865px;
  overflow-x: hidden;
  overflow-y: visible;
  top: 200px;
  left: 0;
}

@media screen and (min-width: 2200px) {
  .storytellingArea {
    width: 1150px;
    padding: 20px 100px;
    left: auto;
  }
}

@media screen and (max-width: 1024px) {
  .storytellingArea {
    top: auto;
    bottom: 200px;
    width: auto;
    padding: 10px 5vw;
  }

  .textAbout {
    left: 130px;
  }
}

@media screen and (max-width: 600px) {
  .storytellingArea {
    padding: 0;
    left: auto;
    right: auto;
    width: 100vw;
  }

  .textAbout {
    top: 0;
    left: 130px;
    right: 2vw;
  }
}

@media screen and (max-width: 370px) {
  .textAbout {
    left: 80px;
  }
}
