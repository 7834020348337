@font-face {
  font-family: "monarchFont";
  src: local("monarchFont"),
    url("../../../fonts/monarch-display/MonarchDisplay-Tantum.otf");
}

.roadmapArea {
  height: 1400px;
  padding-top: 100px;
  padding-bottom: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#roadmap {
  user-select: none;
}

.roadmapTitle {
  font-size: 35pt;
}

.roadMapContainer {
  display: flex;
  flex-direction: column;
  width: 70vw;
  height: auto;
  top: 320px;
  overflow: hidden;
  gap: 10px;
  margin-top: 50px;
}

.roadMapContainer > p {
  color: white;
  width: 8vw;
  height: auto;
  text-align-last: center;
  padding: 1vw;
}

.agarthaRoadmapDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 35%;
  left: 10px;
  height: 130px;
  color: white;
  margin: 15px 0;
  gap: 10px;
}

.topContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.botContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.whiteVerticalSeparationBar {
  width: 1px;
  margin: auto 15px;
  height: 130px;
  background-color: rgb(218, 218, 218);
}

.whiteHorizontalSeparationBar {
  height: 1px;
  margin: 15px auto;
  width: 30vw;
  max-width: auto;
  background-color: rgb(218, 218, 218);
}

.svgContainer7 {
  width: 100%;
}

.roundGreen {
  width: 12px;
  height: 12px;
  background-color: rgb(11 102 11);
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.roundGreenFlash {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-top: 3px;
  animation: greenBlink 1.5s infinite;
}

.roundYellow {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-top: 3px;
  animation: yellowBlink 1.5s infinite;
}

@keyframes yellowBlink {
  0% {
    background: rgb(181 150 0);
  }
  50% {
    background: rgb(94, 94, 94);
  }
  100% {
    background: rgb(181 150 0);
  }
}

@-webkit-keyframes yellowBlink {
  0% {
    background: rgb(181 150 0);
  }
  50% {
    background: rgb(94, 94, 94);
  }
  100% {
    background: rgb(181 150 0);
  }
}

@keyframes greenBlink {
  0% {
    background: rgb(11 102 11);
  }
  50% {
    background: rgb(94, 94, 94);
  }
  100% {
    background: rgb(11 102 11);
  }
}

@-webkit-keyframes greenBlink {
  0% {
    background: rgb(11 102 11);
  }
  50% {
    background: rgb(94, 94, 94);
  }
  100% {
    background: rgb(11 102 11);
  }
}

.roundGray {
  width: 12px;
  height: 12px;
  background-color: rgb(94, 94, 94);
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin-top: 3px;
}

@media screen and (max-width: 1049px) {
  .roadmapArea {
    height: 1600px;
  }

  .roadMapContainer {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 650px;
    gap: 25px;
  }

  .agarthaRoadmapDetails {
    width: 100%;
    height: 35%;
    margin: 0 15px;
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }

  .rightContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
  }
}

@media screen and (max-width: 450px) {
  .roadMapContainer {
    width: 90vw;
  }

  .botContainer {
    left: 2vw;
  }

  .topContainer {
    right: 7vw;
  }

  .roadmapTitle {
    margin-top: 100px;
  }

  .agarthaRoadmapDetails {
    margin: 0 5px;
  }
}
