.svgRune2 {
  position: absolute;
  width: 1935px;
  height: 850px;
  bottom: 15px;
  left: 5vw;
  right: auto;
}

@media screen and (min-width: 2200px) {
  .svgRune2 {
    left: auto;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .svgRune2 {
    left: -300px;
    right: auto;
  }
}

@media screen and (max-width: 375px) {
  .svgRune2 {
    left: -340px;
    right: auto;
  }
}
