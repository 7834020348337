.interestWeb3Area {
  height: auto;
  position: relative;
  display: flex;
  justify-content: flex-start;
  padding-top: 1250px;
  margin-top: 50px;
}

.svgContainer11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1100px;
  overflow-x: hidden;
}

.interestWeb3Informations {
  width: 60vw;
  margin-top: 50px;
  margin-left: 100px;
  height: auto;
  min-height: 300px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.interestWeb3Informations > h3 {
  text-align: left;
  font-size: 18pt;
  margin: 0;
}

.interestWeb3Informations > p {
  text-align: left;
  font-size: 11pt;
  text-align-last: left;
}

.homeButton {
  height: 65px;
  width: 150px;
  border-radius: 8px !important;
  border: 2px solid #26272b !important;
  color: white !important;
  background: #1b1b1b !important;
  border-radius: 50px;
  /* -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; */
}

.homeButton:hover {
  color: #26272b !important;
  background: white !important;
}

@media screen and (max-width: 550px) {
  .interestWeb3Area {
    justify-content: center;
    margin-bottom: 100px;
    padding-top: 850px;
  }

  .svgContainer11 {
    top: -400px;
  }

  .interestWeb3Informations {
    width: 80vw;
    margin: 0;
    padding: 20px;
    align-items: center;
  }

  .interestWeb3Informations > h3 {
    text-align: center;
  }

  .interestWeb3Informations > p {
    text-align: center;
    text-align-last: center;
  }
}
