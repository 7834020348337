.labsAboutArea {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  height: auto;
  /* min-height: 700px; */
  align-items: center;
  margin-bottom: 100px;
  padding-bottom: 150px;
}

.labsAboutBlock {
  display: flex;
  flex-direction: column;
  width: 50vw;
  margin-left: 5vw;
  margin-top: 10px;
  margin-bottom: 70px;
  padding: 20px;
}

.labsAboutBlock > h2 {
  font-size: 35pt;
  line-height: 60px;
}

.labsAboutBlock > p {
  text-align: left;
  text-align-last: auto;
  line-height: 22px;
  font-size: 12pt;
}

@media screen and (max-width: 750px) {
  .labsAboutBlock {
    width: auto;
    min-width: 60vw;
    max-width: 80vw;
    padding-top: 50px;
  }
}

@media screen and (max-width: 500px) {
  .labsAboutBlock > h2 {
    font-size: 30pt;
  }
}
