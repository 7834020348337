.joinFormContainer {
  display: flex;
  width: 80vw;
  min-height: 660px;
  background-color: #1b1b1b;
  border-radius: 5px;
}

.labsFooterArea {
  position: relative;
  height: 940px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.labsLogosArea {
  display: flex;
  justify-content: space-around;
  width: 30vw;
  min-width: 250px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.leftBlockForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  margin-left: 20px;
}

.leftBlockForm > img {
  width: 20vw;
  min-width: 250px;
  max-width: 350px;
  margin-top: 0;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
}

.leftBlockForm > h2 {
  font-size: 25pt;
  line-height: 51px;
  width: 20vw;
  min-width: 285px;
  text-align: center;
  color: white;
}

.rightBlockForm {
  display: flex;
  width: 100%;
  margin: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  text-align: center;
}

.infoForm {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
}

.toSentForm {
  display: flex;
  flex-direction: column;
}

.labsContactFormSendButton {
  color: white !important;
  background: linear-gradient(
    45deg,
    #191364 0%,
    #139a73 50%,
    #cfe4ae 100%
  ) !important;
  transition: scale 250ms !important;
}

.labsContactFormSendButton:hover {
  scale: 0.99;
}

.oneLine {
  display: flex;
  flex-direction: row;
}

.redPoint {
  color: red;
}

.toSentForm > textarea {
  margin-bottom: 60px;
  border: 1px solid gray;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: medium;
}

.textField {
  height: 50px;
  width: 18vw;
  border-radius: 15px;
  border: 1px solid gray;
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: medium;
}

.rightBlockForm > form {
  width: 100%;
}

.svgContainer5 {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  top: -5vh;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 1025px) {
  .leftBlockForm > h2 {
    width: auto;
    margin-top: 50px;
  }

  .infoForm {
    flex-direction: column;
  }

  .rightBlockForm {
    width: auto;
    margin-top: 10px;
  }

  .joinFormContainer {
    flex-direction: column;
  }

  .toSentForm > textarea {
    width: 100%;
  }

  .leftBlockForm {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  }

  .leftBlockForm > img {
    width: 30vw;
    margin-top: 50px;
    margin-bottom: 0;
    min-width: 200px;
  }

  .joinFormContainer {
    height: auto;
  }

  .rightBlockForm > form {
    width: 100%;
  }

  .textField {
    width: 100%;
  }

  .labsFooterArea {
    height: auto;
  }
}

@media screen and (max-width: 425px) {
  .leftBlockForm > h2 {
    font-size: 20pt;
  }
}
