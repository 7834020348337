.privacyBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80%;
  background-color: black;
  overflow-y: scroll;
  color: white;
  padding: 10px 2vw;
  border-left: 1px solid #26272b;
  border-top: 1px solid #26272b;
  border-bottom: 1px solid #26272b;
  box-sizing: border-box;
}

.privacyBoxContainer {
  margin-left: 2vw;
  margin-right: 2vw;
}

.privacyBoxContainer > button {
  color: white !important;
}

#modal-modal-title {
  text-align: center;
}

.sc2::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.sc2::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.sc2::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}
