.labsHeader {
  display: flex;
  position: fixed;
  top: 0;
  padding: 10px 20px;
  z-index: 2;
  width: 98%;
  width: -webkit-fill-available;
  border-bottom: 1px solid #26272b;
  background-color: #000000;
}

.logo {
  width: 350px;
  margin-top: 50px;
  margin-left: 50px;
}

.labsHeaderSection {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.headerBar__button {
  color: white !important;
  font-size: auto !important;
}

.headerBar__button > h4 {
  font-size: small;
  margin: auto;
  font-weight: normal;
}

.labsHeaderBar__link {
  text-decoration: none;
}

.disabledButton {
  color: grey !important;
}

.burgerMenuIcon {
  display: none !important;
  color: white;
}

.labsSmallScreenTopHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.logoHeaderBar {
  display: flex;
  align-items: center;
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.labsHeaderLinks {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1vw;
  overflow-y: auto;
}

.flagLink {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
  height: 100%;
}

.enterButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 40px;
  padding: 0 !important;
  margin-right: 2vw;
  border: 2px solid #26272b !important;
  border-radius: 8px !important;
  color: white !important;
  background: #1b1b1b !important;
  transition: scale 250ms;
}

.enterButton:hover {
  color: #26272b !important;
  background: white !important;
  scale: 0.95;
}

.labsLogo {
  margin-left: 2vw;
  margin-right: 5vw;
  width: 230px;
  object-fit: contain;
}

.labsLogo:hover {
  cursor: pointer;
  scale: 0.99;
}

.labsLanguageSelectorBlock {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-left: 0;
}

.labsHeaderRightBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.labsHeaderRightBlock > a {
  text-decoration: none;
}

.agarthaAccessButton {
  margin-bottom: 5px !important;
}

.agarthaAccessButton::before {
  content: "";
  position: absolute;
  display: flex;
  top: 80%;
  width: 90%;
  left: 5%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #00a99d 0%,
    #94d5e1 30%,
    #ffc930 40%,
    #ea212e 75%,
    #1c1862 100%
  );
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.labsBannerces2023 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
}

@media screen and (max-width: 1200px) {
  .labsLogo {
    margin-left: 0;
    margin-right: 2vw;
  }

  .headerBar__button {
    font-size: 9pt !important;
  }

  .labsHeaderRightBlock {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .labsHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    padding: 15px 20px;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    width: 100vw;
  }

  .labsHeaderSection {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    height: calc(100vh - 60px);
  }

  .labsHeaderLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: auto;
    border-top: 1px solid #26272b;
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
  }

  .labsHeaderSection > div > a {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    width: 50%;
    border-bottom: 1px solid #26272b;
  }

  .labsHeaderSection > div > button {
    display: block;
    padding: 1.5rem;
  }

  .burgerMenuIcon {
    display: unset !important;
    height: auto !important;
  }

  .cross {
    display: flex;
  }

  .smallScreenTopHeader {
    background-color: #000000;
  }

  .headerLinks {
    justify-content: flex-start;
  }

  .labsLogo {
    width: 200px;
  }

  .labsLanguageSelectorBlock {
    padding: unset;
    margin-top: 0;
  }

  .labsHeaderRightBlock > div {
    padding-bottom: 15px;
  }

  .enterButton {
    width: 100px !important;
    margin-top: 25px !important;
    margin-right: 0;
    margin-bottom: 25px !important;
  }

  .labsHeaderRightBlock {
    flex-direction: column-reverse;
    gap: 0;
  }

  .labsBannerces2023 {
    margin-top: 4px;
  }

  .launchpadAspectContainer {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    width: 50%;
    border-bottom: 1px solid #26272b;
  }
}

@media screen and (max-width: 425px) {
  .labsHomeBlock__buttons {
    flex-direction: column;
    align-items: center;
  }
}
