.teamArea {
  display: flex;
  justify-content: center;
  color: white;
  margin: 100px 100px 0 100px;
  min-height: 100vh;
  align-items: center;
}

.teamPhotos {
  display: block;
  width: 100%;
  border-radius: 1%;
  margin-bottom: 5%;
  /* border: 1px solid #26272b; */
}

.teamContainer {
  width: 90vw;
  padding-top: 2vh;
  overflow-x: hidden;
  height: auto;
  min-height: 700px;
  display: flex;
  flex-wrap: wrap;
}

.multipleTeamPhotos {
  width: 95vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 5px;
}

/* --------------------- */

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.profilOverview {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  align-self: center;
  height: calc(50% - 10px) !important;
  white-space: normal;
  border-radius: 10px;
  scale: 0.95;
  transition: scale 250ms !important;
}

.profilOverview > a {
  width: 100%;
  height: 100%;
}

.profilOverview > img {
  margin-bottom: 5px;
}

.profilOverview:hover {
  scale: 1;
  cursor: pointer;
  box-shadow: rgba(93, 93, 100, 0.123) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.profilName {
  margin: 0;
  color: white;
  text-align: center;
}

.descriptionProfil {
  text-align: center;
  /* overflow: hidden !important; */
  text-align-last: center;
  user-select: text; /* supported by Chrome and Opera */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
}

.swiper-slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: calc(50% - 15px) !important;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.teamAvatar {
  /* display: block; */
  /* width: 100%; */
  height: 60%;
  object-fit: contain;
  max-width: 80vw;
  width: 100%;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.swiper-container {
  width: auto;
}

/* --------------------- */

.teamGlobalContainer {
  height: 100%;
  /* display: flex; */
  flex-direction: column;
}

.arrowLeft {
  position: absolute;
  top: 50%;
  left: 2%;
}

.arrowRight {
  position: absolute;
  top: 50%;
  right: 2%;
}

.teamTitle {
  margin-top: 10vh;
  margin-bottom: -1vh;
  font-size: 35pt;
}

.swiper-button-next {
  color: white !important;
  scale: 0.7;
}

.swiper-button-prev {
  color: white !important;
  scale: 0.7;
}

.descriptionBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}

.profilNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 25px;
  margin: 7px auto 5px auto;
}

@media screen and (min-width: 1000px) {
  .swiper-container {
    width: 1000px;
  }
}

@media screen and (min-width: 767px) {
  .swiper-container {
    width: 767px;
    margin-left: 50px;
  }
}

@media screen and (min-width: 550px) {
  .swiper-container {
    width: 550px;
  }

  .teamContainer {
    height: 70vh;
  }
}

@media screen and (max-width: 549px) {
  .swiper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
