.ourPartnersArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.ourPartnersContainer {
  display: flex;
  gap: 50px;
  justify-content: center;
  width: 70vw;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
}

.ourPartnersTitle {
  text-align: center;
  margin: 50px;
  font-size: 16pt;
}

.ourPartnersSectionContainer {
  display: flex;
}

.ourPartnersSectionContainer > a {
  display: flex;
}

.labsTrustedByImg {
  max-height: 120px;
  width: 7vw;
  min-width: 100px;
  max-width: 200px;
  object-fit: contain;
}

.cliquablePartner {
  transition: scale 250ms;
  display: flex;
}

.cliquablePartner:hover {
  scale: 1.1;
  cursor: pointer;
}
