.academySvg2 {
  position: absolute;
  left: 100px;
  height: 520px;
}

@media screen and (max-width: 1700px) {
  .academySvg2 {
    left: auto;
    right: calc(50vw + 200px);
  }
}

@media screen and (max-width: 1100px) {
  .academySvg2 {
    left: -250px;
  }
}
