.servicesArea {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.mainPageServicesTitle {
  margin-top: 200px;
  margin-bottom: 150px !important;
  font-size: 35pt;
}

.servicesAllAgarthaBlocks {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  margin-left: 100px;
  margin-right: 100px;
}

.servicesAllAgarthaBlocks > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 20vw;
  min-width: 250px;
}

.servicesButton {
  max-width: 200px;
  height: 50px;
}

.servicesAgarthaDescription {
  margin-top: 30px;
  font-size: 12pt;
}

@media screen and (max-width: 1150px) {
  .servicesAllAgarthaBlocks {
    flex-direction: column;
    gap: 70px;
    align-items: center;
  }

  .servicesArea {
    margin-bottom: 50px;
  }
}
