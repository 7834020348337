@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirRoman"),
    url("../../../../fonts/avenir/AvenirLTStd-Roman.otf");
}

.topContainerDetailMarket {
  display: flex;
  justify-content: center;
  margin: 0;
}

.topContainerDetailMarket > h2 {
  font-family: "AvenirRoman";
}
