.getTheStoneBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25vw;
  height: auto;
  min-height: 250px;
  background-color: black;
  color: white;
  padding: 50px;
  border: 1px solid #26272b;
  box-sizing: border-box;
}

.getTheStoneBox > p {
  font-size: 12pt;
}

.getTheStoneBox > img {
  margin: auto;
  pointer-events: none;
}

.getTheStoneBox > h3 {
  margin-top: 10px;
  padding-bottom: 25px;
  border-bottom: 1px solid #26272b;
  text-align: center;
}

.getTheStoneBox > Button {
  margin-top: 25px;
}

@media screen and (max-width: 1400px) {
  .getTheStoneBox {
    min-width: 350px;
  }
}

@media screen and (max-width: 500px) {
  .getTheStoneBox {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 250px;
  }
}
