.App {
  text-align: center;
  color: white;
  background-color: black;
  font-family: "monarchFont";
  src: local("monarchFont"),
    url("../../fonts/monarch-display/MonarchDisplay-Tantum.otf");
}
.CGTitre{
  font-family: "monarchFont";
  src: local("monarchFont"),
    url("../../fonts/monarch-display/MonarchDisplay-Tantum.otf");
}
.CGText{
  
 /*color: black;*/
}

.CGButton{

}