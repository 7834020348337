.labsProjectArea {
  position: relative;
  min-height: 100vh;
}

.labsProjectHeader {
  width: 100vw;
  padding-top: 25px;
  padding-bottom: 10px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.labsProjectMainContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  min-height: 530px;
  padding: 70px 8vw 100px 4vw;
  gap: 70px;
}

.labsProjectDescriptionBlock {
  width: 60%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 30px;
}

.labsProjectOverview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 300px;
  gap: 30px;
}

.labsProjectOverviewPicture {
  background-color: #1b1b1b;
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
}

.labsProjectOverviewPicture > h2 {
  text-align: center;
  padding-bottom: 7px;
}

.labsProjectOverviewButtons {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
}

.labsProjectDescription {
  min-height: 200px;
  height: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.labsProjectOverviewImage {
  position: relative;
  height: 450px;
  width: 800px;
  left: 50%;
  transform: translate(-50%);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.labsProjectDescriptionLinks {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 40px;
}

.labsProjectDescriptionAgarthaSummary {
  display: flex;
  align-items: center;
  margin-left: 10px;
  gap: 50px;
}

.labsProjectAgarthaLabsTitle {
  font-size: 20pt;
  padding-bottom: 7px;
}

.labsProjectDescriptionTitle {
  font-size: 18pt;
}

.labsProjectDescriptionText {
  font-size: 12pt;
  text-align: left;
  text-align-last: left;
}

.labsProjectOverviewInfoButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-width: 220px;
  height: 60px;
  border-radius: 20px;
  background-color: #1b1b1b;
}

.labsProjectOverviewInfoButtons > p {
  font-size: 12pt;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.labsProjectDescriptionContainer {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 20px;
}

.labsProjectButton {
  height: 45px;
  /* max-width: 200px; */
  min-width: 150px !important;
  width: 100%;
  border-radius: 12px !important;
  border: 2px solid #1b1b1b !important;
  background: linear-gradient(
    45deg,
    #3f3f3f 0%,
    #009974 50%,
    #68bf91 100%
  ) !important;
  padding: 0 !important;
  transition: scale 250ms !important;
}

.labsProjectButton > a {
  text-decoration: none;
}

/* .labsProjectButton:hover {
  scale: 0.95;
} */

.labsProjectReturnButton::before {
  content: "";
  position: absolute;
  display: flex;
  top: 80%;
  width: 90%;
  left: 5%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(45deg, #cfe4ae 0%, #139a73 50%, #191364 100%);
}

.labsProjectHeaderButtons {
  display: flex;
  align-items: center;
}

.svgContainerLabsProjectLeft {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin: 0;
}

.svgContainerLabsProjectRight {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
  margin: 0;
}

.labsProjectLinkApparence {
  text-decoration: none;
}

.labsProjectWhitelistAgartha {
  width: 100% !important;
  border-radius: 20px !important;
  height: 60px !important;
}

.labsProjectStepsAgartha {
  width: 200px;
  border-radius: 20px !important;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.labsProjectDescriptionAgarthaState {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.labsProjectWhitelistAgartha {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #26272b !important;
  background: white !important;
  border-radius: 50px;
  transition: scale 250ms !important;
}

.labsProjectWhitelistAgartha:hover {
  color: white !important;
  background: #1b1b1b !important;
  scale: 0.95;
}

.labsProjectDescriptionLeftBlock {
  display: flex;
  gap: 70px;
}

.labsProjectOrganizer {
  display: flex;
  gap: 70px;
  width: 100%;
  align-items: unset;
}

.labsProjectBuyInformations {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
}

.labsProjectBuyInformations > p {
  margin: 0;
}

.labsProjectBuyContainer {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  background-color: #1b1b1b;
  border-radius: 20px;
  padding: 20px;
  gap: 10px;
}

@media screen and (max-width: 1700px) {
  .labsProjectDescriptionLeftBlock {
    gap: 3vw;
  }

  .labsProjectDescriptionAgarthaSummary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }

  .labsProjectMainContainer {
    gap: 3vw;
  }

  .labsProjectOverviewButtons {
    justify-content: center;
  }

  .labsProjectOrganizer {
    gap: 3vw;
  }
}

@media screen and (max-width: 1400px) {
  .labsProjectDescriptionAgarthaSummary {
    align-items: center;
    margin-top: 5px;
    flex-wrap: wrap;
    gap: 0 20px;
    justify-content: flex-start;
    flex-direction: row;
  }

  .labsProjectMainContainer {
    align-items: center;
    flex-direction: row-reverse;
  }

  .labsProjectOverviewButtons {
    width: 80%;
  }

  .labsProjectDescriptionLeftBlock {
    width: 80%;
  }

  .labsProjectOverview {
    width: auto;
  }

  .labsProjectOrganizer {
    width: calc(40% - 2.5vw);
  }
}

@media screen and (max-width: 1024px) {
  .labsProjectDescriptionAgarthaSummary {
    justify-content: center;
    flex-direction: column !important;
    align-items: flex-start;
    gap: 0 !important;
  }

  .labsProjectDescription {
    padding: 15px 30px;
    margin-left: 0;
    padding-left: 0;
    padding-top: 0;
  }

  .labsProjectMainContainer {
    padding: 80px 4vw 100px 4vw;
    gap: 7vw;
  }

  .labsProjectDescriptionBlock {
    margin-left: 5vw;
  }

  .labsProjectOverviewInfoButtons {
    width: 100%;
  }

  .labsProjectButton {
    width: 100%;
    min-width: 110px !important;
    max-width: unset;
  }

  .labsProjectOverviewButtons {
    gap: 30px;
  }

  .labsProjectOverview {
    margin-right: calc(5vw - 15px);
    min-width: 250px;
  }

  .labsProjectOrganizer {
    gap: 30px;
  }
}

@media screen and (max-width: 749px) {
  .labsProjectMainContainer {
    flex-direction: column-reverse;
    align-items: center;
    padding: 20px;
  }

  .labsProjectDescriptionAgarthaSummary {
    flex-direction: row;
    align-items: center;
    gap: 5vw;
    margin-left: 0;
  }

  .labsProjectDescription {
    margin-left: 0;
    padding: 10px;
    width: 280px;
    max-width: unset;
  }

  .labsProjectDescriptionBlock {
    width: 100%;
    align-items: center;
    margin-left: 0;
  }

  .labsProjectOverviewButtons {
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 30px;
  }

  .labsProjectOverview {
    margin-right: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid #26272b;
    min-width: 350px;
  }

  .labsProjectOverviewPicture {
    margin-bottom: 30px;
  }

  .labsProjectOverviewInfoButtons {
    width: 100%;
  }

  .labsProjectHeaderButtons {
    border-bottom: 1px solid #26272b;
    padding-bottom: 20px;
  }

  .labsProjectHeader {
    padding-top: 20px;
    gap: 20px;
  }

  .labsProjectOrganizer {
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
    max-width: 80vw;
  }

  .labsProjectDescriptionTitle {
    text-align: center;
  }

  .labsProjectDescriptionText {
    text-align: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 500px) {
  .labsProjectDescriptionLeftBlock {
    width: 80vw;
  }

  .labsProjectOrganizer {
    align-items: center;
    width: 100%;
  }

  .labsProjectDescription {
    width: auto;
  }

  .labsProjectDescriptionAgarthaSummary {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .labsProjectOverview {
    min-width: unset;
    width: 100%;
  }

  .labsProjectOverviewInfoButtons {
    flex-direction: column;
    min-height: 100px;
    gap: 0;
    width: 100%;
  }

  .labsProjectHeaderButtons {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    width: 80vw;
    flex-wrap: wrap;
    justify-content: center;
  }

  .labsProjectHeader {
    gap: 30px;
    height: auto;
  }

  .labsProjectDescriptionBlock {
    width: 80vw;
  }

  .labsProjectMainContainer {
    gap: 20px;
  }

  .labsProjectDescriptionLinks {
    width: 100%;
    justify-content: space-evenly;
  }

  .labsProjectOverviewInfoButtons > p {
    margin: 0;
    width: 90%;
  }

  .labsProjectOverviewButtons {
    width: 100%;
    min-width: unset;
  }

  .labsProjectBuyInformations {
    margin: 0;
  }

  .labsProjectBuyInformations > p {
    font-size: 10pt;
  }
}
