.studioMetaverseIcon {
  width: 7vw;
  max-width: 120px;
  min-width: 110px;

  border-radius: 50%;
  border: double 3px transparent;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(45deg, #191364 0%, #139a73 50%, #cfe4ae 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@media screen and (max-width: 750px) {
  .studioMetaverseIcon {
    width: 15vw;
    min-width: 80px;
  }
}
