.svgRune5 {
  position: absolute;
  height: 1100px;
  width: auto;
  left: 130px;
  top: 0;
}

@media screen and (max-width: 400px) {
  .svgRune5 {
    left: auto;
    right: -1390px;
  }
}
