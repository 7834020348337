.academyAboutArea {
  display: flex;
  align-items: center;
  min-height: 600px;
  position: relative;
  justify-content: flex-end;
  margin-top: 100px;
  margin-bottom: 200px;
}

.svgContainer13 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow-y: visible;
  overflow-x: hidden;
}

.academyAboutContainer {
  display: flex;
  flex-direction: column;
  width: 50vw;
  max-width: 1200px;
  min-width: 500px;
  margin-right: 100px;
}

.academyAboutContainer > h2 {
  font-size: 50pt;
  line-height: 122%;
  margin: 40px 0;
}

.academyAboutContainer > p {
  font-size: 14pt;
  text-align: left;
  text-align-last: left;
  margin-bottom: 40px;
}

@media screen and (max-width: 1100px) {
  .academyAboutArea {
    min-height: unset;
    padding-bottom: 700px;
    justify-content: center;
  }

  .svgContainer13 {
    height: 600px;
    bottom: 0;
  }

  .academyAboutContainer {
    width: 60vw;
    margin-left: 10vw;
    margin-right: 10vw;
    min-width: unset;
    align-items: center;
  }

  .academyAboutContainer > h2 {
    text-align: center;
  }

  .academyAboutContainer > p {
    text-align: center;
    text-align-last: center;
  }
}

@media screen and (max-width: 750px) {
  .academyAboutContainer > h2 {
    font-size: 30pt;
  }
  .academyAboutContainer > p {
    font-size: 13pt;
  }
}
