body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"), url("./fonts/avenir/Avenir-Black.ttf");
}

@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirRoman"), url("./fonts/avenir/AvenirLTStd-Roman.otf");
}

@font-face {
  font-family: "AvenirLight";
  src: local("AvenirLight"), url("./fonts/avenir/Avenir-Light2.ttf");
}

* {
  font-family: "AvenirLight";
}

h1,
h2,
h3 {
  font-family: "AvenirBlack";
  color: white;
}

h4 {
  margin: 0;
}

p {
  font-family: "AvenirRoman";
  text-align: center;
  text-align-last: center;
  font-size: 11pt;
}

a {
  font-family: "AvenirLight";
  color: white !important;
  font-size: 11pt;
}

li {
  color: white;
}

em {
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none;
}

h1 {
  font-size: 20pt;
}

h2,
h3 {
  font-size: 14pt;
}

.monarchFont {
  font-family: "monarchFont" !important;
}

.labsScrollSectionsTitles {
  display: flex;
  margin-bottom: 5vh;
  justify-content: center;
  font-size: 35pt;
  line-height: 69px;
}

p[value="Agartha"] {
  font-family: "monarchFont" !important;
}
