.memberModalContainer {
  background-color: transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.memberModalDetails {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  max-width: 300px;
  height: auto;
  max-height: 80%;
  overflow-y: auto;
  background-color: black;
  color: white;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(89, 89, 99, 0.185) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-radius: 10px;
  border: 1px solid #26272b;
}

.profileTeamModalPicture {
  object-fit: contain;
  width: 70vw;
  width: -webkit-fill-available;
  max-width: 260px;
}

.memberModalCloseIcon {
  position: absolute;
  scale: 1.5;
  top: 15px;
  right: 20px;
  color: #26272b;
  transition: color 50ms !important;
  z-index: 2;
}

.memberModalCloseIcon:hover {
  cursor: pointer;
  color: #4b4c50;
}

.longDescriptionProfil {
  border-top: 1px solid #26272b;
  padding-top: 20px;
}

.memberModalLinkContainer {
  height: 20px;
}
