.labsEnterArea {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
}

.labsEnterMainBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 120px;
  padding-bottom: 120px;
  width: 100%;
  height: 100%;
}

.stepsBlock {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  gap: 50px;
  padding-top: 40px;
  border-top: 2px solid white;
  margin-bottom: 100px;
}

.stepBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 240px;
  border: 3px solid #ffffff;
  border-radius: 15px;
  min-height: 350px;
}

.labsEnterTitle {
  font-size: 30pt;
  margin-bottom: 0;
  text-align: center;
}

.stepBlock > h3 {
  text-align: center;
  font-size: 19pt;
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 43px;
}

.stepBlockText {
  font-size: 13pt;
  color: white;
  text-align: center;
  text-align-last: center;
  line-height: 25px;
}

.justifiedText {
  text-align: left;
  text-align-last: start;
}

.whiteButton {
  color: white !important;
  border-color: white !important;
}

.disabledButton {
  color: gray !important;
}

.labsHomeButton {
  height: 65px;
  width: 150px;
}

.stepBlock > a {
  display: flex;
}

.svgContainerLabsEnterLeft {
  position: absolute;
  display: flex;
  align-items: flex-end;
  overflow-y: visible;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.svgContainerLabsEnterRight {
  position: absolute;
  display: flex;
  top: 0;
  justify-content: flex-end;
  overflow-y: hidden;
  overflow-x: hidden;
  height: -webkit-fill-available;
  min-height: 100vh;
  width: 100%;
  margin-top: 10px;
  z-index: -1;
}

.labsEnterReturnButton {
  margin-bottom: 10px !important;
}

.labsEnterSubtitles {
  display: flex;
  gap: 30px;
  padding: auto;
  margin-top: auto;
  justify-content: space-between;
  width: 80vw;
}

.labsEnterLanguageButton {
  margin-right: -10px;
  margin-bottom: 10px;
}

.labsEnterReturnButton::before {
  content: "";
  position: absolute;
  display: flex;
  top: 80%;
  width: 90%;
  left: 5%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(45deg, #cfe4ae 0%, #139a73 50%, #191364 100%);
}

.labsEnterLinkApparence {
  text-decoration: none;
}

.returnBackText {
  margin: 0;
}

.labsEnterSignInButton {
  width: 150px !important;
}

.backButton {
  display: flex;
  gap: 5px;
}

@media screen and (max-width: 1025px) {
  .labsEnterMainBlock {
    margin-top: 50px;
  }

  .stepsBlock {
    flex-direction: column;
    align-items: center;
    max-width: 400px;
    margin-bottom: 200px;
  }

  .labsEnterTitle {
    margin-top: 150px;
  }

  .svgContainerLabsEnterRight {
    overflow-y: visible;
    height: -webkit-fill-available;
  }

  .labsEnterSubtitles {
    max-width: 400px;
  }

  .labsEnterLanguageButton {
    padding: 0;
    margin-top: 0;
    justify-content: center;
  }

  .labsEnterReturnButton {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 650px) {
  .labsEnterTitle {
    padding: 0 10px;
  }

  .labsEnterSubtitles {
    width: 60vw;
    flex-wrap: wrap;
    gap: 0;
    justify-content: center;
  }

  .labsEnterLanguageButton {
    margin-left: 40px;
  }

  .svgContainerLabsEnterLeft {
    margin-left: -30px;
    overflow-x: visible;
  }

  .svgContainerLabsEnterRight {
    margin-right: -110px;
    overflow-x: visible;
  }

  .stepsBlock {
    width: 60vw;
  }

  .stepBlock {
    width: 60vw;
    height: auto;
  }

  .stepBlock > h3 {
    font-size: 18pt;
    line-height: 40px;
  }

  .stepBlockText {
    font-size: 12pt;
    line-height: 28px;
  }
}
