@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirRoman"),
    url("../../../../fonts/avenir/AvenirLTStd-Roman.otf");
}

.bannerContainer {
  top: 0;
  left: 0;
  height: 50px;
  width: 100vw;
  background: linear-gradient(90deg, #0d1925 0%, #002434 50%, #003839 100%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  gap: 20px;
}

.bannerContainer > img {
  height: 70%;
}

.ces2023BannerButton {
  height: 80%;
  margin: 0;
  color: white !important;
  border-radius: 8px;
  background-color: #0d1925 !important;
  border: 1px solid #09080e !important;
  transition: scale 250ms !important;
}

.ces2023BannerButton:hover {
  background-color: #0d1925 !important;
  border: 1px solid #26272b !important;
  scale: 0.95;
}

.ces2023BannerButton > h4 {
  font-size: small;
  margin: 0;
  font-weight: bold;
}

.bannerContainer > a {
  text-decoration: none;
}

.ces2023BannerCross {
  position: absolute;
  right: 20px;
  color: white;
  cursor: pointer;
}

.smallScreenInfoCes2023 {
  display: flex;
  gap: 2px 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.smallScreenInfoCes2023Title {
  margin: 0;
  font-size: 14pt;
  font-family: "AvenirRoman";
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 750px) {
  .bannerContainer {
    justify-content: flex-start;
  }

  .bannerContainer > img {
    margin-left: 15px;
  }

  .ces2023BannerButton {
    margin-right: 60px !important;
  }

  .smallScreenInfoCes2023Title {
    font-size: 11pt;
  }

  .ces2023BannerCross {
    right: 10px;
  }
}
