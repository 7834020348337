.labsSvgContainer1 {
  position: absolute;
  overflow-y: visible;
  overflow-x: hidden;
  top: 0;
  left: 0;
  height: auto;
}

.labsSvgRune1 {
  position: relative;
  height: 1820px;
}
