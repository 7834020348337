.courseModalDetails {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  max-width: 1000px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: black;
  color: white;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: rgba(89, 89, 99, 0.185) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  border-radius: 10px;
  border: 1px solid #26272b;
}

.courseModalDetails > h2 {
  align-self: center;
  font-size: 32px;
  text-align: center;
}

.courseModalDetails > h3 {
  margin-left: 10px;
}

.whitemodaccordeon > div > svg {
  color: rgba(85, 85, 85, 0.5) !important;
}

.courseOverviewAccordions {
  background-color: #101010 !important;
  color: white !important;
  margin-bottom: 3px !important;
  margin-top: 0 !important;
}

.courseOverviewAccordions:first-of-type {
  border: none !important;
}

.modalEnterCourseButton {
  width: 70% !important;
  align-self: center;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.formation-details-list > li > p {
  text-align-last: left;
}

.courseModalDetails--white-mod {
  background-color: white;
  border-color: white;
}

.courseModalDetails--white-mod::-webkit-scrollbar-thumb {
  background-color: rgba(85, 85, 85, 0.5) !important;
}

.whitemodkoe {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.courseModalDetails--white-mod > * {
  color: #2b3e54;
}

.whitemodbutton {
  display: flex;
  height: 65px;
  width: 300px;
  max-width: 80vw;
  border-radius: 8px !important;
  border: 2px solid #1565d8 !important;
  color: white !important;
  background: #1565d8 !important;
  border-radius: 50px;
  transition: scale 250ms !important;
}

.whitemodbutton:hover {
  scale: 0.98;
}

.whitemod {
  background-color: transparent !important;
  color: black !important;
  box-shadow: none !important;
}

.titremodwhite {
  font-weight: bold !important;
  color: #2b3e54 !important;
  margin: 10px 0;
  line-height: 36.8px;
  font-size: 22px;
}

.whitemodlist > *,
.whitemodlist > li > p,
.whitemodlist > li > h4 {
  color: #2b3e54;
  text-align-last: left;
  text-align: left;
}

@media screen and (max-width: 500px) {
  .courseModalDetails {
    width: 90vw;
  }
}
