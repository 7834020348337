.labsHomeArea {
  position: relative;
  display: flex;
  min-height: 920px;
}

.labsHomeBlock {
  align-items: center;
  width: 40vw;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 7vw;
}

.labsHomeBlock__description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.labsHomeBlock__description > p {
  text-align: left;
  text-align-last: auto;
  line-height: 22px;
  font-size: 13pt;
  margin-top: 30px;
  margin-bottom: 30px;
}

.labsHomeBlock__description > h1 {
  font-size: 52pt;
  line-height: 85px;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 575px;
  display: inline-block;
}

.labsWhatBlock {
  min-height: 540px;
  height: auto;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 200px;
  margin-left: 3vw;
}

.labsWhatBlock__blocks {
  width: 23vw;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
}

.disabledButton {
  color: gray !important;
}

.labsHomeButton {
  height: 65px;
  width: 150px;
  border-radius: 8px !important;
  border: 2px solid #26272b !important;
  color: white !important;
  background: #1b1b1b !important;
  border-radius: 50px;
  /* -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out; */
  transition: scale 250ms !important;
}

.labsHomeButton:hover {
  color: #26272b !important;
  background: white !important;
  font-size: 15px;
  scale: 0.95;
}

/* .labsHomeButton:hover {
    background-position: 100% 0 !important;
    -moz-transition: all .4s ease-in-out !important;
    -o-transition: all .4s ease-in-out !important;
    -webkit-transition: all .4s ease-in-out !important;
    transition: all .4s ease-in-out !important;
}

.labsHomeButton:focus {
    outline: none;
}

.color-11 {
    background-image: linear-gradient(to right, #eb3941, #f15e64, #e14e53, #e2373f) !important;
    box-shadow: 0 5px 15px rgba(242, 97, 103, .4);
} */

.labsHomeBlock__buttons {
  display: flex;
  gap: 5vw;
}

.labsHomeBlock__buttons > a {
  text-decoration: auto;
  width: auto;
}

.labsWhatBlock__blocks > h2 {
  font-size: 24pt;
  line-height: 35px;
  text-align: center;
}

.labsWhatBlock__blocks > p {
  text-align: center;
  font-size: 13pt;
  line-height: 22px;
}

.lastBlock {
  margin-bottom: auto;
}

.op {
  text-align: center;
}

.op > p {
  width: max-content;
}

@media screen and (max-width: 1025px) {
  .labsHomeBlock {
    width: 50vw;
    margin-top: 180px;
  }
}

@media screen and (max-width: 750px) {
  .labsHomeBlock {
    width: 70vw;
  }

  .labsWhatBlock {
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 50px;
  }

  .labsWhatBlock__blocks {
    width: 60vw;
  }

  .lastBlock {
    margin-bottom: 50px;
  }

  .labsHomeBlock__description > h1 {
    min-width: unset;
    font-size: 44pt;
    line-height: 75px;
  }
}

@media screen and (max-width: 500px) {
  .labsWhatBlock {
    margin-left: 30px;
  }

  .labsWhatBlock__blocks {
    width: 70vw;
  }

  .labsWhatBlock__blocks > h2 {
    font-size: 20pt;
    line-height: 35px;
  }

  .labsHomeBlock {
    width: 75vw;
    margin-left: 10vw;
  }

  .labsHomeBlock__description > h1 {
    font-size: 30pt;
    line-height: 50px;
  }
}

@media screen and (max-width: 425px) {
  .labsHomeButton {
    width: 70vw !important;
  }
}
