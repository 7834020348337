.labsServicesArea {
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 800px;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 135px;
}

.svgContainer3 {
  position: absolute;
  overflow-y: visible;
  overflow-x: hidden;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 1025px;
  height: -webkit-fill-available;
}

.labsServices__line {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
}

.servicesBlock {
  display: flex;
  flex-direction: column;
  width: 20vw;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.secondLine {
  width: 25vw;
  margin-top: 20px;
}

.labsScrollSectionsTitles {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: center;
}

.servicesBlockDescription > h3 {
  font-size: 22pt;
  margin-top: 15px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
}

.servicesBlock > p {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 18px;
  font-size: 12pt;
}

.servicesTitle {
  margin-bottom: 40px;
}

.servicesBlockDescription {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

.svgContainer6 {
  position: absolute;
  overflow-y: visible;
  overflow-x: hidden;
  left: 0;
  top: -985px;
  height: 1025px;
  width: 100%;
}

@media screen and (max-width: 1010px) {
  .servicesTitle {
    margin-bottom: 30px;
  }

  .servicesBlock {
    width: 25vw;
  }

  .secondLine {
    width: 35vw;
  }

  .servicesBlockDescription > h3 {
    font-size: 18pt;
  }

  .labsServices__line {
    margin-right: 15px;
  }
}

@media screen and (max-width: 750px) {
  .servicesTitle {
    margin-top: 35px;
    margin-bottom: 70px;
  }

  .labsServices__line {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    gap: 50px;
  }

  .servicesBlock {
    flex-direction: column;
    gap: 20px;
    width: 50vw;
  }

  .servicesBlockDescription > h3 {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 30vw;
  }

  .servicesBlock > p {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .servicesBlockDescription {
    flex-direction: row;
    gap: 20px;
    justify-content: space-evenly;
  }

  .servicesLastLine {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 500px) {
  .servicesBlock {
    width: 70vw;
    margin-right: 15px;
  }

  .servicesTitle {
    font-size: 30pt;
  }
}
