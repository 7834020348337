.svgRune1 {
  position: absolute;
  width: 1350px;
  bottom: 10px;
  right: 7vw;
  height: auto;
}

@media screen and (max-width: 767px) {
  .svgRune1 {
    right: -260px;
    top: auto;
    bottom: 0;
  }
}

@media screen and (min-height: 1190px) {
  .svgRune1 {
    top: 0;
  }
}
