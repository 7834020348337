@font-face {
  font-family: "monarchFont";
  src: local("monarchFont"),
    url("../../../../fonts/monarch-display/MonarchDisplay-Tantum.otf");
}

.agarthaButtonRoadmap {
  color: white;
  display: flex;
  gap: 10px;
  width: 100%;
  height: 100%;
}

.roadmapDetailedContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border: 1px solid #26272b;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10vh 20px;
}

.agarthaButtonRoadmap > h2 {
  color: white;
}

.topContainerLabsDetails {
  position: relative;
  display: flex;
  width: 100%;
}

.topContainerDetails > p {
  text-align: center;
}

.botContainerLabsDetails {
  position: relative;
  display: flex;
  justify-content: center;
  width: 80.5%;
  /* 31px for the separation bar and her margins */
}

.agarthaRoadmapModalDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: inherit;
  left: 10px;
  color: white;
  margin: 0;
  font-size: 11pt;
}

.roadmapTextContainer {
  flex-direction: row;
}

.monarchFont {
  font-family: "monarchFont";
}

.littleHorizontalSize {
  height: 80px;
}

@media screen and (max-width: 750px) {
  .agarthaButtonRoadmap {
    gap: 0;
  }
}
