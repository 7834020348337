.swisstechLogo {
  height: 120px;
  width: 12vw;
  min-width: 150px;
  max-width: 200px;
  object-fit: contain;
}

@media screen and (max-width: 500px) {
  .swisstechLogo {
    min-width: 110px;
  }
}
