.svgRune3 {
  position: absolute;
  height: 800px;
  bottom: 0;
  right: 20vw;
}

@media screen and (min-width: 1850px) {
  .svgRune3 {
    right: auto;
    left: 0;
  }
}
