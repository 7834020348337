.changeCurrentyContainerButton {
  display: flex;
  justify-content: center;
  height: 25px;
}

.MuiPopover-paper {
  margin-top: 10px;
  background-color: #1b1b1b !important;
}

.changeCurrentyButton {
  background-color: #1b1b1b !important;
}

.currentiesContainer {
  display: flex;
  gap: 50px;
  background-color: transparent !important;
  border-top-left-radius: 0 !important;
}

.currentiesContainer > p {
  padding: 0 !important;
}

.css-17ffvgn-MuiTypography-root {
  padding-top: 8px !important;
  padding-bottom: 10px !important;
}

.currentiesChangeLink {
  height: 25px;
}
