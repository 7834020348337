.calendarWindow {
  width: 100%;
  height: 100%;
}

.calendarBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  height: 80%;
  background-color: white;
  overflow-y: scroll;
  color: white;
  border-left: 1px solid #26272b;
  border-top: 1px solid #26272b;
  border-bottom: 1px solid #26272b;
  box-sizing: border-box;
}

.calendarBoxContainer {
  height: 100%;
}

.rdvButton {
  width: auto;
  padding-left: 20px !important;
  padding-right: 20px !important;
  transition: scale 250ms !important;
}

.rdvButton:hover {
  scale: 0.95;
}

.hover-cursor:hover {
  cursor: wait;
}
