.svgRune4 {
  position: relative;
  bottom: 0;
  z-index: 0;
  height: 770px;
  width: 1056px;
  left: auto;
}

@media screen and (max-width: 1056px) {
  .svgRune4 {
    left: calc((100vw - 1056px) / 2);
  }
}
