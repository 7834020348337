.header {
  display: flex;
  position: fixed;
  justify-content: flex-end;
  top: 0;
  padding: 10px 20px;
  z-index: 2;
  width: 98%;
  width: -webkit-fill-available;
  background-color: #000000;
  border-bottom: 1px solid #26272b;
}

.logo {
  width: 350px;
  margin-top: 50px;
  margin-left: 50px;
  object-fit: contain;
}

.headerSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.headerBarButton {
  color: white !important;
}

.headerBar__link {
  text-decoration: none;
  margin-bottom: 5px;
}

.disabledButton {
  color: grey !important;
}

.burgerMenuIcon {
  display: none !important;
  color: white;
}

.smallScreenTopHeader {
  display: none;
  justify-content: space-between;
  padding: 10px 20px;
}

.logoHeaderBar {
  display: flex;
  align-items: center;
  -webkit-animation-name: fadeInOpacity;
  animation-name: fadeInOpacity;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.headerLinks {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
}

.flagLink {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 5px;
  cursor: pointer;
  height: 100%;
}

.languageSelectorBlock {
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-left: 0;
}

.labsAccessButton::before {
  content: "";
  position: absolute;
  display: flex;
  top: 80%;
  width: 90%;
  left: 5%;
  height: 2px;
  border-radius: 2px;
  background: linear-gradient(45deg, #191364 0%, #139a73 50%, #cfe4ae 100%);
}

.headerBarButton > h4 {
  font-size: small;
  margin: 0;
  font-weight: normal;
}

@media screen and (max-width: 1024px) {
  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    padding: 15px 20px;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    width: 100vw;
  }

  .headerSection {
    display: flex;
    flex-direction: column;
    background-color: #000000;
    height: calc(100vh - 56.5px);
  }

  .headerLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    height: 100%;
    border-top: 1px solid #26272b;
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }

  .headerLinks > a {
    display: block;
    padding: 1.5rem;
    width: 60%;
    border-bottom: 1px solid #26272b;
  }

  .headerBarButton {
    display: block;
    padding: 1.5rem;
  }

  .burgerMenuIcon {
    display: unset !important;
    height: auto !important;
  }

  .cross {
    display: flex;
  }

  .smallScreenTopHeader {
    display: flex;
    background-color: #000000;
  }

  .languageSelectorBlock {
    padding: unset;
    margin-top: 30px;
    margin-bottom: 2vh;
  }
}
