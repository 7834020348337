.homeArea {
  position: relative;
  display: flex;
  min-height: 800px;
  height: 100vh;
}

.textCatch {
  position: absolute;
  color: white;
  padding: 20px;
  letter-spacing: 4px;
  margin: 0;
  margin-bottom: 100px;
  right: calc(7vw + 500px);
  left: auto;
  bottom: 350px;
  top: auto;
  font-size: 14pt;
}

.svgContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

@media screen and (max-width: 1200px) {
  .textCatch {
    width: 300px;
    right: calc(15vw + 270px);
    left: auto;
  }
}

@media screen and (max-width: 767px) {
  .svgContainer {
    height: calc(100% + 10px);
  }

  .textCatch {
    width: 70vw;
    left: auto;
    right: 50px;
    bottom: 320px;
  }
}

@media screen and (min-height: 1190px) {
  .textCatch {
    top: 600px;
    bottom: auto;
  }
}
