.labsUpcomingArea {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labsUpcomingProjectsContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 50px;
  height: 100%;
  padding: 80px 50px 100px 50px;
  position: relative;
}

.labsUpcomingProjectsContainer > h1 {
  position: absolute;
  top: 30px;
  text-align: center;
}

.labsUpcomingProjectBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 350px;
  border-radius: 15px;
  min-height: 350px;
}

.labsProjectHeader > img {
  pointer-events: none;
}

.labsUpcomingProjectImage {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.labsUpcomingProjectState {
  background-color: #1b1b1b;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.labsUpcomingProjectTitle {
  background-color: #1b1b1b;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 15px;
  height: 70px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
blank .labsUpcomingProjectDescriptionBlock {
  background-color: #1b1b1b;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 20px;
}

.labsUpcomingProjectDescription {
  margin-bottom: 30px;
}

@media screen and (max-width: 500px) {
  .labsUpcomingProjectsContainer {
    padding: 0 10px 50px 10px;
  }

  .labsUpcomingProjectsContainer > h1 {
    position: relative;
    margin: 0;
  }

  .labsUpcomingProjectBlock {
    padding: 10px;
  }
}
