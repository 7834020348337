.labsProjectFooterContents {
  position: relative;
  bottom: 0;
  padding: 30px 30px 20px 30px;
  color: #fff;
  background-color: #000000;
  border-top: 1px solid #26272b;
}

.footerMainContent {
  display: flex;
  text-align: justify;
}

.footerDescription {
  margin-bottom: 1em;
}

.footerBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.7);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.sectionTitle {
  margin: 20px auto;
}

.linkApparence {
  height: 36.5px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.mailto {
  color: #464646 !important;
  margin-left: 100px;
}

.rightsBlock {
  display: flex;
  align-items: center;
}

.svgContainer8 {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  overflow: hidden;
}

@media screen and (min-width: 24.375em) {
  .legal .legalLinks {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footerNav > * {
    flex: 1;
  }

  .navItem--extra {
    flex-grow: 2;
  }

  .footerDescription {
    flex: 8 0px;
  }

  .footerNav {
    flex: 2 0px;
  }
}

@media screen and (max-width: 1250px) {
  .footerMainContent {
    flex-direction: column;
  }

  .footerDescription {
    margin-bottom: 0;
  }

  .labsProjectFooterContents {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  .mailto {
    margin-left: 50px;
  }
}

@media screen and (max-width: 450px) {
  .rightsBlock {
    flex-direction: column-reverse;
  }

  .mailto {
    margin-left: 0;
  }

  .labsProjectFooterContents {
    padding-left: 20px;
    padding-right: 20px;
  }
}
