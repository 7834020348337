.labsProjectTabContainer {
  width: auto;
  max-width: 500px;
  padding: 20px 0 0 0;
  border-radius: 20px;
  margin-top: 100px;
}

.labsProjectTab {
  color: white !important;
}

.MuiTabs-indicator {
  background-color: white !important;
  justify-content: center;
}

.labsProjectTabUtility {
  min-height: 605px !important;
}

.labsProjectTabUtility > * {
  font-size: 12pt;
}

.labsProjectSwiperTeam {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
}

.MuiTabs-flexContainer {
  justify-content: center;
}

.labsProjectRoadmapSteps {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.labsProjectTeamContainer {
  width: 90vw;
  overflow-x: hidden;
  height: 550px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.labsProjectTeamDescriptionBlock {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.labsProjectTabUtility > p:first-of-type {
  padding-top: 30px;
}

.labsProjectRoadmapSteps:first-of-type {
  padding-top: 15px;
}

.labsProjectRoadmapSteps:last-of-type {
  margin-bottom: 30px;
}

.MuiTabScrollButton-horizontal {
  color: white !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3 !important;
}

.labsLinkButton {
  height: 65px;
  width: 100%;
  border-radius: 10px !important;
  min-width: 150px;
  border: none !important;
  color: #43f0a0 !important;
  transition: scale 250ms !important;
}

.labsLinkButton:hover {
  scale: 0.95;
}

.css-tkn350-MuiTabs-root {
  justify-content: center !important;
}

.css-69z67c-MuiTabs-scroller {
  display: flex !important;
  width: 100%;
  width: fit-content;
  flex: unset !important;
}

.lightProfileOverview {
  min-width: 10%;
}

@media screen and (max-width: 1400px) {
  .labsProjectDescriptionLeftBlock {
    flex-direction: column;
    align-items: center;
  }

  .labsProjectDescriptionBlock {
    width: auto;
  }

  .labsProjectMainContainer {
    gap: 5vw;
  }

  .labsProjectTabContainer {
    margin-left: 0;
    margin-top: 0;
    max-width: 80vw;
    width: calc(50% - 2.5vw);
  }
}

@media screen and (max-width: 749px) {
  .labsProjectTabContainer {
    width: 100%;
    margin-top: 0;
  }

  .labsProjectDescriptionLeftBlock {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .lightLabsSwiper {
    display: block;
  }
}
