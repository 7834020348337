.academyHomeArea {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  margin-top: 75px;
  min-height: 750px;
}

.academyHomeContentContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45vw;
  max-width: 1200px;
  min-width: 500px;
  margin-left: 100px;
  margin-bottom: 50px;
}

.academyHomeContentContainer > h1 {
  font-size: 50pt;
  line-height: 122%;
  margin: 40px 0;
}

.academyHomeContentContainer > p {
  font-size: 14pt;
  text-align: left;
  text-align-last: left;
  margin-bottom: 40px;
}

.svgContainer12 {
  position: absolute;
  overflow-y: visible;
  overflow-x: hidden;
  top: 100px;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media screen and (max-width: 1100px) {
  .academyHomeArea {
    flex-direction: column;
    align-items: center;
    padding-bottom: 600px;
    margin-top: 150px;
    min-height: unset;
  }

  .academyHomeContentContainer {
    margin: 0;
    width: 80vw;
    padding-bottom: 50px;
    align-items: center;
  }

  .academyHomeContentContainer > h1 {
    text-align: center;
  }

  .academyHomeContentContainer > p {
    text-align: center;
    text-align-last: center;
  }

  .svgContainer12 {
    height: 600px;
    top: auto;
    bottom: 0;
  }
}

@media screen and (max-width: 750px) {
  .academyHomeContentContainer {
    min-width: unset;
  }
  .academyHomeContentContainer > h1 {
    font-size: 30pt;
  }

  .academyHomeContentContainer > p {
    font-size: 13pt;
  }
}
