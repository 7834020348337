.labsHeaderLaunchpadButton {
  background-color: transparent !important;
  margin: 0 !important;
}

.labsHeaderLaunchpadButton > h4 {
  margin: 0;
  font-family: "AvenirLight";
  font-weight: normal;
}

.labsHeaderLaunchpadButtonPopoverContainer > div {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.labsHeaderLaunchpadButtonPopover {
  background-color: black !important;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid #26272b;
  border-left: 1px solid #26272b;
  border-right: 1px solid #26272b;
}

h4 > a {
  width: 100%;
}
