.svgContainer4 {
  position: absolute;
  display: flex;
  overflow-y: visible;
  overflow-x: hidden;
  bottom: 0;
  left: 0;
  width: 100%;
}

.labsSvgRune4 {
  position: relative;
  width: 40vw;
  max-width: 710px;
  min-width: 500px;
}
