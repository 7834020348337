@font-face {
  font-family: "monarchFont";
  src: local("monarchFont"),
    url("../../../fonts/monarch-display/MonarchDisplay-Tantum.otf");
}

.footerArea {
  display: flex;
  align-items: flex-end;
  position: relative;
  min-height: 900px;
  height: auto;
}

.footerContents {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  bottom: 0;
  padding: 30px 30px 20px 30px;
  color: #fff;
  background-color: #000000;
  border-top: 1px solid #26272b;
}

.footerMainContent {
  display: flex;
  text-align: justify;
}

.footerDescription {
  margin-bottom: 1em;
}

.footerDescriptionText {
  text-align: left;
  text-align-last: left;
}

.footerBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.7);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.linksSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13vw;
}

.sectionTitle {
  margin: 20px auto;
}

.linkApparence {
  height: 36.5px;
  width: 50%;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  color: white !important;
}

.legalSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 13vw;
}

.moreLegalSection {
  display: flex;
  justify-content: space-between;
}

.mailto {
  color: #464646 !important;
  margin-left: 100px;
}

.multipleLinksSection {
  display: flex;
  justify-content: space-evenly;
  margin: 0px 0 2vh 3vw;
}

.rightsBlock {
  display: flex;
  align-items: center;
}

.svgContainer8 {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  overflow: hidden;
  z-index: -1;
}

.footerInternalLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.internalLinkTitle {
  text-transform: capitalize;
  font-size: 12pt !important;
}

.disabledAccessButton {
  color: #646464 !important;
}

@media screen and (min-width: 24.375em) {
  .legal .legalLinks {
    margin-left: auto;
  }
}

@media screen and (min-width: 40.375em) {
  .footerNav > * {
    flex: 1;
  }

  .navItem--extra {
    flex-grow: 2;
  }

  .footerDescription {
    flex: 8 0px;
  }

  .footerNav {
    flex: 2 0px;
  }
}

@media screen and (max-width: 1250px) {
  .footerMainContent {
    flex-direction: column;
  }

  .linksSection {
    width: 30vw;
    margin-top: 0;
  }

  .legalSection {
    width: 30vw;
    margin-top: 0;
  }

  .footerDescription {
    margin-bottom: 0;
  }

  .footerContents {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  .mailto {
    margin-left: 50px;
  }

  .multipleLinksSection {
    margin: 10px 0 20px 0;
  }
}

@media screen and (max-width: 767px) {
  .moreLegalSection {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 10px;
  }
}

@media screen and (max-width: 550px) {
  .multipleLinksSection {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 30px 0 30px 0;
  }
}

@media screen and (max-width: 450px) {
  .rightsBlock {
    flex-direction: column-reverse;
  }

  .mailto {
    margin-left: 0;
  }

  .linksSection {
    width: 40vw;
  }

  .legalSection {
    width: 40vw;
  }

  .footerContents {
    padding-left: 20px;
    padding-right: 20px;
  }
}
