.agarthaButtonRoadmap {
  color: white;
  width: 100%;
  height: 100%;
}

.roadmapDetailedContainerVertical {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #26272b;
  box-sizing: border-box;
  width: 100%;
  height: 680px;
  gap: 25px;
  padding: 30px;
}

.agarthaButtonRoadmap > h2 {
  color: white;
}

.leftContainerDetailsLabsVertical {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  height: 100%;
  margin-bottom: auto;
}

.rightContainerDetailsLabsVertical {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  height: calc(100% - 130px);
}

.agarthaRoadmapDetailsVertical {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  margin: 0;
}

.leftContainerDetailsVertical > p {
  text-align: center;
  font-size: 11pt;
}

.rightContainerDetailsVertical > p {
  text-align: center;
  font-size: 11pt;
}

.roadmapDetailedModalVertical {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  max-width: 80vw;
  height: auto;
  max-height: 80vh;
  background-color: black;
  border-left: 1px solid #26272b;
  border-top: 1px solid #26272b;
  border-bottom: 1px solid #26272b;
  overflow: auto;
  box-sizing: border-box;
}

.littleVerticalSize {
  max-width: 200px;
}

@media screen and (max-width: 750px) {
  .agarthaButtonRoadmap {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .roadmapDetailedContainerVertical {
    gap: 10px;
  }

  .roadmapDetailedModalVertical {
    width: 90vw !important;
  }
}
