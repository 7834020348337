.logosArea {
  display: flex;
  justify-content: space-around;
  width: 30vw;
}

.logosArea > img {
  margin-left: 20px;
  margin-right: 20px;
}

.logosStyle {
  height: 30px;
}

.logosArea > a {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .logosArea {
    width: 50vw;
    min-width: 220px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
