.box {
  color: black;
}

.labsLitepaperModal {
  width: 80vw;
  height: 80%;
  background-color: black;
  overflow-y: scroll;
  color: white;
  border-left: 1px solid #26272b;
  border-top: 1px solid #26272b;
  border-bottom: 1px solid #26272b;
  box-sizing: border-box;
  margin: auto;
  position: relative;
}

.labsLitepaperModalContainer {
  margin-left: 2vw;
  margin-right: 2vw;
}

.agarthaLitepaperPdf {
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}

.sc2::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.sc2::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.sc2::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

.rpv-core__inner-pages {
  height: auto !important;
}

.labsLittlepaperModalContainer {
  display: flex;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 500px) {
  .labsLitepaperModal {
    width: 90vw;
  }
}
